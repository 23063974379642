<!--
 * @Description: 欠费订单 lackOrder
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-07-19 15:56:06
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="欠费订单"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="main">
      <van-checkbox-group v-model="result"
                          ref="checkboxGroup"
                          @change="groupChange">
        <van-row class="list"
                 v-for="(item,index) in lackOrderList"
                 :key=index>
          <van-col style="width:100%">
            <van-row class="lineOne">
              <van-col>
                <van-checkbox icon-size="17px"
                              style="margin-left:10px;margin-top:15px"
                              :name="item">
                </van-checkbox>
              </van-col>
              <van-col class="lineOnePlate"
                       style="margin-left:10px">
                {{item.plateNumber}}
              </van-col>
              <van-col class="lineOnePlateColor">
                <van-tag type="primary">{{item.plateColor}}</van-tag>
              </van-col>
              <van-col class="lineOneMoney">
                {{item.lackOrderMoney}}元
              </van-col>
            </van-row>
            <van-row class="lineTwo"></van-row>
            <van-row class="lineThree">
              <van-col :span=12
                       class="lineThreeLeft">
                <van-row class="lineThreeLeft-lineOne">开始时间</van-row>
                <van-row class="lineThreeLeft-lineTwo">{{item.entranceTime}}</van-row>
                <van-row class="lineThreeLeft-lineThree">停车时长</van-row>
                <van-row class="lineThreeLeft-lineFour">{{item.parkDuration}}</van-row>
              </van-col>
              <van-col :span=12
                       class="lineThreeRight">
                <van-row class="lineThreeRight-lineOne">结束时间</van-row>
                <van-row class="lineThreeRight-lineTwo">{{item.leaveTime}}</van-row>
                <van-row class="lineThreeRight-lineThree"
                         @click="handleClickDetail(item)">
                  订单详情
                  <img src="@/assets/homePage/rig.png"
                       alt="">
                </van-row>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-checkbox-group>
    </van-row>
    <van-row class="selectAll">
      <van-col :span="6">
        <van-checkbox v-model="selectAll"
                      @click="changeAll(selectAll)"
                      icon-size="17px"
                      style="margin:12px 0 0 12px;font-size:15px;color: #333333;">
          {{selectAll?'全不选':'全选'}}
        </van-checkbox>
      </van-col>
      <van-col :span="5"
               style="font-size:13px;color:#666666;">
        总金额：
      </van-col>
      <van-col :span="3"
               style="font-size:19px;color:#19A9FC;text-align:right">
        {{money}}
      </van-col>
      <van-col :span="1"></van-col>
      <van-col :span="2"
               style="font-size:15px;color: #333333;">
        元
      </van-col>
      <van-col :span="7"
               style="font-size:17px;color: #FFFFFF;background:#19A9FC;text-align: center;"
               @click="handleClickNext">
        下一步
      </van-col>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
// import formatDuration from '@/utils/duration'
import parkStopTime from '@/utils/parkStopTime'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      money: '0.00', // 开票金额
      selectAll: false, // 全选或全不选
      result: [], // 多选绑定数组
      lackOrderList: [], // 可开票订单获取数组
      valOrder: [], // 订单数组
      lackOrderLength: 0, // 数据总数
      orderIds: []
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getLackOrder()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 点击下一步
    handleClickNext () {
      if (Number(this.money) !== 0) {
        this.$router.push({
          name: 'choosePayStyle',
          query: {
            orderIds: this.orderIds,
            payMoney: Number(this.money),
            style: '补缴',
            page: 'lackOrder',
            plateNumber: this.lackOrderList[0].plateNumber,
            numberPlateColorCode: this.lackOrderList[0].numberPlateColorCode
          }
        })
      } else {
        this.$toast('请先选择')
      }
    },
    // 获取欠费订单
    getLackOrder () {
      const info = {
        plateNumber: this.$route.query.plateNumber,
        numberPlateColorCode: this.$route.query.numberPlateColorCode
      }
      this.$lackOrder.queryArrangeOrderByPlateCode(info).then(res => {
        for (let i = 0; i < res.resultEntity.order.length; i++) {
          res.resultEntity.order[i].lackOrderMoney = Number(Number(res.resultEntity.order[i].receivableMoneyAmount - res.resultEntity.order[i].receivedMoneyAmount) / 100).toFixed(2)
          res.resultEntity.order[i].receivableMoneyAmount = Number(Number(res.resultEntity.order[i].receivableMoneyAmount) / 100).toFixed(2)
          // res.resultEntity.order[i].parkDuration = formatDuration(res.resultEntity.order[i].parkDuration)
          res.resultEntity.order[i].parkDuration = parkStopTime(res.resultEntity.order[i].entranceTime, res.resultEntity.order[i].leaveTime)
        }
        this.lackOrderLength = res.resultEntity.order.length
        this.lackOrderList = res.resultEntity.order
        if (this.lackOrderList.length === 0) {
          this.$router.go(-1)
        }
        // 默认全选
        this.$nextTick(() => {
          this.changeAll(true)
        })
      })
    },
    // 多选组发生改变
    groupChange (names) {
      this.orderIds = []
      if (names.length < this.lackOrderLength) {
        this.selectAll = false
      } else {
        this.selectAll = true
      }
      let money = 0
      this.valOrder = names
      names.forEach((item) => {
        money += Number(item.lackOrderMoney)
        this.orderIds.push(item.orderSequence)
      })
      this.money = money.toFixed(2)
    },
    // 全选或全不选
    changeAll (state) {
      this.$refs.checkboxGroup.toggleAll(state)
    },
    // 订单详情点击事件
    handleClickDetail (item) {
      this.$router.push({
        name: 'lackOrderDetail',
        query: { lackOrderDetail: JSON.stringify(item) }
      })
    },
    // 顶部返回
    handleClickTopBack () {
      this.$router.push('/payForParking')
    }
  }
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .main {
    width: 100%;
    height: calc(100% - 70px - 20px);
    overflow-x: hidden;
    .list {
      width: 93.6%;
      height: 150px;
      margin-left: 3.2%;
      background-color: #fff;
      margin-top: 10px;
      .lineOne {
        width: 100%;
        height: 45.5px;
        line-height: 45.5px;
        position: relative;
        .lineOnePlate {
          font-size: 17px;
          color: #333333;
        }
        .lineOnePlateColor {
          margin-left: 10px;
          line-height: 42.5px;
        }
        .lineOneMoney {
          float: right;
          position: absolute;
          right: 10px;
          font-size: 17px;
          color: #f56060;
        }
      }
      .lineTwo {
        width: 100%;
        height: 1px;
        background: #e7e8e8;
      }
      .lineThree {
        padding-left: 20px;
        .lineThreeLeft {
          width: 50%;
          .lineThreeLeft-lineOne {
            color: #666666;
            font-size: 12px;
            height: 25px;
            line-height: 25px;
          }
          .lineThreeLeft-lineTwo {
            color: #333333;
            font-size: 15px;
            height: 25px;
            line-height: 25px;
          }
          .lineThreeLeft-lineThree {
            color: #666666;
            font-size: 12px;
            height: 25px;
            line-height: 25px;
          }
          .lineThreeLeft-lineFour {
            color: #333333;
            font-size: 15px;
            height: 25px;
            line-height: 25px;
          }
        }
        .lineThreeRight {
          padding-left: 10px;
          width: 50%;
          .lineThreeRight-lineOne {
            color: #666666;
            font-size: 12px;
            height: 25px;
            line-height: 25px;
          }
          .lineThreeRight-lineTwo {
            color: #333333;
            font-size: 15px;
            height: 25px;
            line-height: 25px;
          }
          .lineThreeRight-lineThree {
            color: #333333;
            font-size: 15px;
            height: 50px;
            line-height: 50px;
          }
        }
      }
    }
  }
  .selectAll {
    width: 100%;
    height: 46px;
    line-height: 46px;
    background-color: white;
    position: absolute;
    bottom: 0;
  }
}
</style>
